import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SidebarContext } from '../contexts/ActivePageContext';
// import { formatCreatedAt } from "../../../utils/dateUtils";
// import clock from "./assets/Clock.svg";
import briefcase from './assets/Briefcase.svg';
import down from './assets/CaretDown.svg';
import MyAccount from './myAccount';
import burger from './assets/Menu_Duo_MD.svg';
import Plan from './plan';
import Transactions from './transactions';
import logo from './assets/Logo.svg';
import GetHelp from './getHelp';

const Headbar = () => {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [myAccountVisible, setMyAccountVisible] = useState(false);
	const [myPlanVisible, setMyPlanVisible] = useState(false);
	const [getHelpVisible, setGetHelpVisible] = useState(false);
	const [myTransactionsVisible, setMyTransactionsVisible] = useState(false);
	const dropdownRef = useRef(null);
	const navigate = useNavigate();
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

	const { sidebarVisible, setSidebarVisible } = useContext(SidebarContext);

	const handleLodOut = () => {
		localStorage.clear();
		navigate('/');
	};

	const handleGoHome = () => {
		navigate('/dashboard');
	};

	const toggleSidebar = () => {
		setSidebarVisible(!sidebarVisible);
	};

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	const toggleMyPlan = () => {
		setMyPlanVisible(!myPlanVisible);
		setDropdownVisible(false); // Close dropdown when opening MyPlan
	};

	const toggleMyAccount = () => {
		setMyAccountVisible(!myAccountVisible);
		setDropdownVisible(false); // Close dropdown when opening MyAccount
	};

	const toggleGetHelp = () => {
		setGetHelpVisible(!getHelpVisible);
		setDropdownVisible(false); // Close dropdown when opening GetHelp
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
			// setMyAccountVisible(false);
		}
	};

	const toggleMyTransactions = () => {
		setMyTransactionsVisible(!myTransactionsVisible);
		setDropdownVisible(false); // Close dropdown when opening MyPlan
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const closeModal = () => {
		setMyAccountVisible(false);
	};

	const [doraToken, setDoraToken] = useState(null);
	const [profileDetails, setProfileDetails] = useState([]);

	useEffect(() => {
		// Get the item from localStorage
		const storedItem = localStorage.getItem('dora_token');
		// Set the token if it exists
		if (storedItem) {
			setDoraToken(storedItem);
		} else {
			// Navigate to login if the token does not exist
			navigate('/login');
		}
	}, [navigate]);

	// get user details
	useEffect(() => {
		// Only fetch profile details if doraToken is available
		if (doraToken) {
			const url = new URL(`${API_BASE_URL}api/v1/profile`);

			const headers = {
				Authorization: `Bearer ${doraToken}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			};

			fetch(url, {
				method: 'GET',
				headers,
			})
				.then((response) => response.json())
				.then((data) => {
					setProfileDetails(data.data || {});
				})
				.catch((error) => {
					console.error('Error fetching profile details:', error);
				});
		}
	}, [doraToken]);

	useEffect(() => {
		if (profileDetails && profileDetails.name) {
			localStorage.setItem('dora_UserName', profileDetails.name);
			localStorage.setItem('template_id', profileDetails.template_id);
		}
	}, [profileDetails]);

	useEffect(() => {
		if (profileDetails?.expired) {
			navigate('/TrialEnd');
		} else if (profileDetails?.plan === 'Professional' && !profileDetails?.expired) {
			// Do nothing, remain on the same page
		}
	}, [profileDetails, navigate]);

	// console.log(profileDetails);

	return (
		<>
			<div className=' w-full lg:w-[75%] fixed top-0 lg:left-[25%] h-[70px] px-4 md:px-8 py-6 z-[999] border-l border-b bg-[#f5f5f5] border-[#EAEBF0] flex flex-row justify-between items-center '>
				<div className='space-x-0 md:space-x-3 lg:space-x-0 flex items-center'>
					<img src={burger} className=' w-6 z-[9999] hidden md:flex lg:hidden' alt='Menu' onClick={toggleSidebar} />
					<img src={logo} className=' md:hidden' alt='Dorascribe Logo' onClick={handleGoHome} />
					<span className=' hidden md:flex items-center space-x-1'>
						{/* <img src={clock} alt="" /> */}
						<p className=' font-Inter font-semibold text-sm text-[#282D2D]'>
							{/* {formatCreatedAt(profileDetails.created_at)} */}
						</p>
					</span>
				</div>
				<div className=' flex space-x-3 items-center'>
					<span className=' py-[2px] px-2 rounded-[5px] bg-[#E1EAEA] hidden md:flex items-center space-x-1'>
						<img src={briefcase} className='' alt='' />
						{profileDetails && (
							<p className=' text-sm text-[#282D2D] font-semibold font-Inter leading-[18px]'>{profileDetails.plan}</p>
						)}
					</span>
					<button
						onClick={toggleDropdown}
						className='py-[10px] md:px-4 space-x-3 flex items-center relative'
						ref={dropdownRef}
					>
						{/* <span className="rounded-full w-6 h-6 bg-[#fff]"></span> */}
						{profileDetails && (
							<p className='font-Inter font-semibold text-sm text-black capitalize'>{profileDetails.name}</p>
						)}
						<img
							src={down}
							alt='Caret Down'
							className={`transition-transform ${dropdownVisible ? 'rotate-180' : 'rotate-0'}`}
						/>
						{/* Dropdown Menu  */}
						{dropdownVisible && (
							<div className='absolute swing-in-top-fwd w-full min-w-32 right-0 top-12 bg-white border border-[#DAE0E6] py-1 shadow-sm shadow-[#1018280A]'>
								<button
									className='w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm'
									onClick={toggleMyAccount}
								>
									My Account
								</button>
								<button
									onClick={toggleMyPlan}
									className='w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm'
								>
									Plan
								</button>
								{/* <button className="w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm">
                  Billing
                </button> */}
								<button
									onClick={toggleMyTransactions}
									className='w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm'
								>
									Transactions
								</button>
								<Link to='/tutorials' className=' w-full'>
									<button className='w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm'>
										Dorascribe Tutorials
									</button>
								</Link>
								<button
									onClick={toggleGetHelp}
									className='w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm'
								>
									Get Help
								</button>
								<button
									onClick={handleLodOut}
									className='w-full px-4 py-[10px] text-left font-Inter text-[#272D37] font-normal text-sm'
								>
									Log out
								</button>
							</div>
						)}
					</button>
				</div>
			</div>
			{myAccountVisible && <MyAccount closeModal={closeModal} profileDetails={profileDetails} />}
			{myPlanVisible && <Plan closePlanModal={toggleMyPlan} profileDetails={profileDetails} />}
			{getHelpVisible && <GetHelp closeHelpModal={toggleGetHelp} profileDetails={profileDetails} />}
			{myTransactionsVisible && <Transactions closeTransactionsModal={toggleMyTransactions} />}
		</>
	);
};

export default Headbar;

