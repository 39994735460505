import { useState, useEffect } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link, useNavigate } from "react-router-dom";
import { handleUserRegistrationWithPassword } from "../../controllers/UserController";
import SignUPOTP from "./signUpOTP";
import { useGTM } from "../../utils/useGTM";

const SignUp = () => {
  useGTM();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [specialty_id, setSpecialty_id] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [specialties, setSpecialties] = useState([]);
  const [view, setView] = useState("password"); // State to toggle between views
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const onSuccess = () => {
    navigate("/chooseplan");
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setErrorMessage(""); // Clear the error message
      const userData = { email, name, specialty_id, password };
      handleUserRegistrationWithPassword(userData, onSuccess);
    } else {
      setErrorMessage("Passwords do not match");
    }
  };

  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}api/v1/specialties`);
        if (!response.ok) {
          throw new Error("Failed to fetch specialties");
        }
        const data = await response.json();
        setSpecialties(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSpecialties();
  }, []);

  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex flex-col items-center justify-center overflow-y-auto">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            Welcome to Dorascribe
          </p>
          <p className="mt-1 text-[#282D2D] font-Inter font-normal text-lg">
            Your medical AI assistant
          </p>

          <div className="w-full md:w-[500px] p-1 flex flex-row border border-[#EAEBF0] rounded-[60px] mt-2">
            <button
              className={`w-full py-3 rounded-[45px] text-center font-Inter font-medium transition-all text-base ${
                view === "password"
                  ? "bg-[#00AAAA] text-white"
                  : "text-[#282D2D]"
              }`}
              onClick={() => setView("password")}
            >
              Signup with Password
            </button>
            {/* <button
              className={`w-1/2 py-3 rounded-[45px] text-center font-Inter font-medium transition-all text-base ${
                view === "otp" ? "bg-[#00AAAA] text-white" : "text-[#282D2D]"
              }`}
              onClick={() => setView("otp")}
            >
              Signup with OTP
            </button> */}
          </div>

          {/* sign up with password */}

          {view === "password" && (
            <>
              <div className="  w-full md:w-[500px] flex items-center justify-between">
                <input
                  type="text"
                  className="mt-3 border border-[#EAEBF0] w-[48%] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div className=" w-[48%] mt-3 border border-[#EAEBF0] rounded-[50px]  px-6 py-4">
                  <select
                    className="w-full bg-transparent font-Inter font-normal text-sm text-[#282D2D] custom-select"
                    value={specialty_id}
                    onChange={(e) => setSpecialty_id(e.target.value)}
                  >
                    <option value="">Select Specialty</option>
                    {specialties.map((specialty) => (
                      <option key={specialty.id} value={specialty.id}>
                        {specialty.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <input
                type="text"
                className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="  w-full md:w-[500px] flex items-center justify-between">
                <input
                  type="password"
                  className="mt-3 border border-[#EAEBF0] w-[48%] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
                  placeholder="Create your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  className="mt-3 border border-[#EAEBF0] w-[48%] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
                  placeholder="Confirm your Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {errorMessage && (
                <p className="text-red-500 mt-2 font-Inter text-sm">
                  {errorMessage}
                </p>
              )}

              <button
                className="w-full md:w-[500px] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6"
                onClick={handleSignUpClick}
              >
                Proceed
              </button>
            </>
          )}

          {view === "otp" && <SignUPOTP specialties={specialties} />}

          <p className="mt-3 text-center text-[#282D2D] font-Inter font-normal text-base">
            By signing up, you agree to the terms of Use,{" "}
            <Link className=" text-[#0dabab]" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>
          <p className="absolute bottom-0 text-center text-[#12121266] font-Inter text-base font-normal">
            Already have an Account?{" "}
            <Link to="/login">
              <span className="text-[#0DABAB] font-semibold">Login</span>
            </Link>
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default SignUp;
