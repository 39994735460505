import { Link } from "react-router-dom";
import logo from "./assets/Logo.svg";
import { useEffect, useState } from "react";

const Navbar = () => {
  function overlay() {
    //check classlist
    const overlayDiv = document.getElementById("overlay");
    if (overlayDiv.classList.contains("-translate-y-[500px]")) {
      overlayDiv.classList.remove("-translate-y-[500px]");
    } else if (!overlayDiv.classList.contains("-translate-y-[500px]")) {
      overlayDiv.classList.add("-translate-y-[500px]");
    }
  }

  const [doraToken, setDoraToken] = useState(null);

  useEffect(() => {
    // Get the item from localStorage
    const storedItem = localStorage.getItem("dora_token");
    // Set the token if it exists
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  return (
    <>
      <div
        id="overlay"
        className=" w-full bg-[#fff] backdrop-blur-xl h-[380px] p-6 flex justify-center items-center -translate-y-[500px] shadow transition-all duration-700 top-[78px] fixed z-[99]"
      >
        <div className="w-full flex flex-col justify-center items-center space-y-5">
          <Link to="/">
            <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
              Home
            </p>
          </Link>

          <Link to="/Pricing">
            <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
              Pricing
            </p>
          </Link>
          <a href="https://blog.dorascribe.ai">
            <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
              Blog
            </p>
          </a>
          <Link to="/tutorials">
            <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
              Dorascribe Tutorials
            </p>
          </Link>
          <Link to="/Faqs">
            <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
              FAQs
            </p>
          </Link>

          <a
            href="https://calendly.com/help-dorascribe"
            target="blank"
            className=" font-Inter text-[15px] font-semibold text-[#272D37]"
          >
            Book Demo
          </a>

          <div className=" flex md:hidden z-50 items-center space-x-3 mt-4">
            {doraToken ? (
              <Link to="/dashboard">
                <button className="px-5 py-3 font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white">
                  Go to Dashboard
                </button>
              </Link>
            ) : (
              <>
                <Link to="/login">
                  <button className="px-4 py-[10px] text-base font-BricolageGrotesque rounded-[40px] hover:bg-[#F5F5F5] transition-all font-semibold text-[#121212]">
                    Log in
                  </button>
                </Link>
                <Link to="/signUp">
                  <button className="px-5 py-3 font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white">
                    Sign Up Now
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      <div className=" py-7 px-4 md:px-14 flex flex-row justify-between items-center fixed w-full top-0 bg-white left-0 z-[999]">
        <div className=" flex items-center space-x-16">
          <img src={logo} className="" alt="Dorascribe" />
          <span className=" hidden lg:flex items-center space-x-6 z-10">
            <Link to="/">
              <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
                Home
              </p>
            </Link>

            <Link to="/Pricing">
              <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
                Pricing
              </p>
            </Link>
            <a href="https://blog.dorascribe.ai">
              <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
                Blog
              </p>
            </a>
            <Link to="/tutorials">
              <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
                Dorascribe Tutorials
              </p>
            </Link>
            <Link to="/Faqs">
              <p className=" font-Inter text-[15px] font-semibold text-[#272D37]">
                FAQs
              </p>
            </Link>
          </span>
        </div>
        <div className=" hidden md:flex z-50 items-center space-x-3">
          {doraToken ? (
            <Link to="/dashboard">
              <button className="px-5 py-3 font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white">
                Go to Dashboard
              </button>
            </Link>
          ) : (
            <>
              <Link to="/login">
                <button className="px-4 py-[10px] text-base font-BricolageGrotesque rounded-[40px] hover:bg-[#F5F5F5] transition-all font-semibold text-[#121212]">
                  Log in
                </button>
              </Link>

              <a
                href="https://calendly.com/help-dorascribe"
                target="blank"
                className="px-5 py-3 font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white"
              >
                Book Demo
              </a>

              <Link to="/signUp">
                <button className="px-5 py-3 font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white">
                  Sign Up Now
                </button>
              </Link>
            </>
          )}
          <div
            onClick={overlay}
            className="menu-icon hidden md:block lg:hidden"
          >
            <input className="menu-icon__cheeckbox" type="checkbox" />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div onClick={overlay} className="menu-icon md:hidden">
          <input className="menu-icon__cheeckbox" type="checkbox" />
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
