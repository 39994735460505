import { useEffect, useState } from "react";
import checked from "./assets/checked.svg";
import unchecked from "./assets/unchecked.svg";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [plans, setPlans] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [activePeriod, setActivePeriod] = useState("Month"); // New state to track the active button

  useEffect(() => {
    const url = new URL(`${API_BASE_URL}api/v1/plans`);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  // const plans = [
  //   {
  //     price: "$0",
  //     frequency: "/month",
  //     planName: "Free",
  //     description: "Demonstrate empathy maps and finally improve outcomes.",
  //     features: [
  //       { text: "10 Transcriptions", icon: checked },
  //       { text: "Regular feature updates", icon: checked },
  //       { text: "Advanced Data privacy", icon: unchecked },
  //       { text: "Live Chat Support", icon: unchecked },
  //     ],
  //     buttonText: "Get Started",
  //   },
  //   {
  //     price: "$99",
  //     frequency: "/month",
  //     planName: "Professional",
  //     description: "Demonstrate empathy maps and finally improve outcomes.",
  //     features: [
  //       { text: "10 Transcriptions", icon: checked },
  //       { text: "Regular feature updates", icon: checked },
  //       { text: "Advanced Data privacy", icon: checked },
  //       { text: "Live Chat Support", icon: checked },
  //     ],
  //     buttonText: "Get Started",
  //   },
  // ];

  // Filter plans based on the activePeriod
  const filteredPlans = plans.filter(
    (item) =>
      item.periodicity_type === activePeriod || item.name === "Free Trial" // Show the trial plan regardless of periodicity
  );
  return (
    <>
      <div className=" py-8 md:py-16 px-4 md:px-14 w-full flex justify-center items-center flex-col">
        <h2 className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          Our Pricing
        </h2>
        <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D] lg:w-[800px]">
          We offer the best value for money on the market. Our cost per outcome
          is generally the most competitive.
        </p>
        <div className=" p-1 rounded-[60px] bg-[#F7F7F8] border border-[#EAEBF0] mt-3 flex flex-row space-x-[6px] items-center">
          <button
            className={`w-[48%] py-3 px-8 md:px-16 rounded-[25px] shadow-sm shadow-[#1018280A] font-BricolageGrotesque font-semibold text-sm ${
              activePeriod === "Month"
                ? "bg-[#00aaaa] text-white"
                : "text-[#929292]"
            }`}
            onClick={() => setActivePeriod("Month")}
          >
            Monthly
          </button>
          <button
            className={`w-[48%] rounded-[25px] py-3 px-8 md:px-16 font-BricolageGrotesque font-semibold text-sm ${
              activePeriod === "Year"
                ? "bg-[#00aaaa] text-white"
                : "text-[#929292]"
            }`}
            onClick={() => setActivePeriod("Year")}
          >
            Yearly
          </button>
        </div>
        <div className="mt-8 w-full grid grid-cols-1 md:grid-cols-2 gap-6">
          {filteredPlans.map((item, index) => (
            <div
              key={index}
              className="w-full border border-[#EAEBF0] rounded-[20px] p-6 md:p-8"
            >
              <p className="text-[#272D37] font-BricolageGrotesque font-semibold text-2xl md:text-4xl">
                ${item.price}
                <span className="font-Inter text-[#5F6D7E] font-normal text-base md:text-xl">
                  {" "}
                  {item.name === "Free Trial"
                    ? `for ${item.periodicity} days Trial`
                    : `Per ${item.periodicity_type}`}
                </span>
              </p>
              <p className="text-[#272D37] font-BricolageGrotesque font-medium text-base md:text-xl mt-4">
                {item.name}
              </p>
              <p className="mt-2 font-Inter font-normal text-sm md:text-base text-[#5F6D7E] border-b border-[#EAEBF0] pb-6">
                {item.description}
              </p>
              <div className="py-6 space-y-3">
                <span className="flex flex-row items-center space-x-3">
                  <img src={checked} className=" w-6 md:w-auto" alt="" />
                  <p className="text-[#272D37] font-Inter font-normal text-sm md:text-base">
                    {item.benefits[0]}
                  </p>
                </span>
                <span className="flex flex-row items-center space-x-3">
                  <img src={checked} className=" w-6 md:w-auto" alt="" />
                  <p className="text-[#272D37] font-Inter font-normal text-sm md:text-base">
                    {item.benefits[1]}
                  </p>
                </span>
                <span className="flex flex-row items-center space-x-3">
                  <img src={checked} className=" w-6 md:w-auto" alt="" />
                  <p className="text-[#272D37] font-Inter font-normal text-sm md:text-base">
                    {item.benefits[2]}
                  </p>
                </span>
              </div>
              <Link to="/signUp">
                <button className="w-full py-3 bg-[#00aaaa] shadow rounded-[30px] text-center shadow-[#1018280A] text-white font-BricolageGrotesque text-sm md:text-base font-semibold">
                  Get Started
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Pricing;
